.system-message-root {
  display: flex;
  flex: 1;
  /* justify-content: center; */
}
.system-message-body {
  flex: 1;
  width: 1px;
  text-align: center;
}

.rce-container-mbox {
  flex-direction: column;
  display: block;
  overflow: hidden;
  padding-left: 10px;
}

.rce-mbox-forward {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background: #fff;
  position: absolute;
  /*display: none;*/
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 0 rgba(164, 164, 164, 1);
  cursor: pointer;
  transition: all 0.3s ease;
  top: 0;
  bottom: 0;
  margin: auto;
}

.rce-mbox-forward-center {
  display: flex;
  opacity: 0;
  visibility: hidden;
  margin: auto;
}

.rce-mbox-forward-left {
  display: flex;
  opacity: 0;
  visibility: hidden;
  left: -50px;
}

.rce-mbox-forward-right {
  display: flex;
  opacity: 0;
  visibility: hidden;
  right: -50px;
}

.rce-mbox-reply-btn-left {
  display: flex;
  opacity: 0;
  visibility: hidden;
  left: -85px;
}

.rce-mbox-reply-btn-right {
  display: flex;
  opacity: 0;
  visibility: hidden;
  right: -85px;
}

.rce-container-mbox:hover .rce-mbox-forward-left {
  opacity: 1;
  visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-forward-right {
  opacity: 1;
  visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-reply-btn-left {
  opacity: 1;
  visibility: visible;
}

.rce-container-mbox:hover .rce-mbox-reply-btn-right {
  opacity: 1;
  visibility: visible;
}

.rce-mbox {
  max-width: 80%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  background-color: white;
  margin-left: 50px;
  margin-right: 5px;
  margin-top: 3px;
  flex-direction: column;
  margin-bottom: 3px;
  border-radius: 0 15px 15px;
}
.rce-mbox-greeting {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
.rce-mbox-normal {
  max-width: 80%;
  width: fit-content;
}
.before-comp-container {
  flex: 1;
  margin: 3px 10px 3px 0;
}
.after-comp-container {
  flex: 1;
  margin-left: 50px;
}
.rce-mbox.message-focus {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 1s;
  animation-name: message-box-default-focus;
  animation-duration: 1s;
}

@-webkit-keyframes message-box-default-focus {
  from {
    background-color: #fff;
  }
  to {
    background-color: #dfdfdf;
  }
}

.rce-mbox.rce-mbox-right {
  float: right;
  margin: 0;
  display: inline-block;
  white-space: normal;
  word-break: break-all;
  margin-bottom: 3px;
  max-width: 80%;
  margin-right: 10px;
  border-radius: 15px 0 15px 15px;
  background-color: #102ca1;
  color: #fff;
}

.rce-mbox-body {
  margin: 10px;
  padding: 0;
  position: relative;
}

.rce-mbox.rce-mbox-right.message-focus {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 1s;
  animation-name: message-box-right-focus;
  animation-duration: 1s;
}

@-webkit-keyframes message-box-right-focus {
  from {
    background-color: #d4f1fb;
  }
  to {
    background-color: #b8dae6;
  }
}

.rce-mbox-text {
  font-size: 14px;
  word-break: break-word;
}

.rce-mbox-text .search-icon {
  font-size: 1rem;
  margin-bottom: -2px;
}

.rce-mbox-text .rce-mbox-buttons {
  padding-top: 10px;
}

@media screen and (max-width: 360px) {
  .jss10 {
    font-size: 11px !important;
  }
  .jss11 {
    font-size: 11px !important;
  }
  .jss12 {
    font-size: 11px !important;
  }
  .jss37 {
    font-size: 11px !important;
  }
}

/* p 태그 margin 초기화 */

.rce-mbox-text > div > p {
  margin: 0;
}

/* .rce-mbox-text:after {
    content: "\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0"
} */

.rce-mbox-time {
  text-align: right;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  position: absolute;
  right: -4px;
  bottom: -5px;
}

.rce-mbox-time.non-copiable:before {
  content: attr(data-text);
}

.rce-mbox-time-block {
  /*position: relative;*/
  right: 0;
  bottom: 0;
  left: 0;
  margin-right: -6px;
  margin-left: -6px;
  padding-top: 5px;
  padding-right: 3px;
  padding-bottom: 2px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.33), transparent);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
}

/* .rce-mbox--clear-padding {
    padding-bottom: 3px;
}  */

.rce-mbox.rce-mbox--clear-notch {
  border-radius: 5px 5px 5px 5px !important;
}

.rce-mbox-right-notch {
  display: none;
  /* position: absolute;
    right: -14px;
    top: 0px;
    width: 15px;
    height: 15px;
    fill: #102ca1;
    filter: drop-shadow( 2px 0px 1px rgba(0, 0, 0, .2)); */
}

.rce-mbox-right-notch.message-focus {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 1s;
  animation-name: message-right-notch-focus;
  animation-duration: 1s;
}

@-webkit-keyframes message-right-notch-focus {
  from {
    fill: #d4f1fb;
  }
  to {
    fill: #b8dae6;
  }
}

.rce-mbox-left-notch {
  display: none;
  /* position: absolute;
    left: -14px;
    top: 0px;
    width: 15px;
    height: 15px;
    fill: white; */
}

.rce-mbox-left-notch.message-focus {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-duration: 1s;
  animation-name: message-left-notch-focus;
  animation-duration: 1s;
}

@-webkit-keyframes message-left-notch-focus {
  from {
    fill: #fff;
  }
  to {
    fill: #dfdfdf;
  }
}

.rce-mbox-title {
  margin: 0;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 13px;
  color: #4f81a1;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.rce-mbox-title:hover {
  text-decoration: underline;
}

.rce-mbox-title--clear {
  margin-bottom: 5px;
}

.rce-mbox-status {
  margin-left: 3px;
  font-size: 15px;
}

.rce-mbox-title > .rce-avatar-container {
  margin-right: 5px;
}
