.kakao_faq {
  width: 80%;
  /* display: table; */
  margin: auto;
}

.kakao_faq .faq_contents {
  width: 100%;
  margin: 0 auto;
}

.kakao_faq p {
  text-align: center;
  font-size: 4.5vmin;
  margin: 4vmin 0;
  letter-spacing: -0.2vmin;
}

.kakao_faq p span {
  color: #102ca1;
  font-weight: bolder;
  font-size: 4.5vmin;
}

.kakao_faq .item {
  width: 100%;
  display: flex;
}

.kakao_faq .item .left_avatar {
  display: block;
  width: 40%;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 1vmin;
}

.kakao_faq .item .right_question {
  color: black;
  box-sizing: border-box;
  text-align: left;
  vertical-align: top;
  padding-left: 1.5vmin;
  float: right;
  width: 60%;
  min-height: 35vmin;
}

.kakao_faq .item .right_question p {
  text-align: left;
  padding-bottom: 3%;
  width: 100%;
}

.kakao_faq .item .right_question p span {
  font-weight: bolder;
}

.kakao_faq .item .right_question .speach_bb li {
  cursor: pointer;
  padding: 3% 5%;
  margin: 1%;
  border: 1px solid #858585;
  border-radius: 4em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bolder;
  font-size: 3vmin;
  display: inline-block;
}

.txt_black {
  color: black !important;
}
