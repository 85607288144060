.home_oj_O_1 {
  border: 3px solid #fff;
  width: 34px;
  height: 34px;
  border-radius: 100%;
}
.home_oj_O_2 {
  background-color: #fff;
  width: 2px;
  height: 8vh;
}
.home_oj_O_3 {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #fff;
}
