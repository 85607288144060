@media screen and (min-width: 1280px) {
  .terms-contents {
    width: 50%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 412px) {
  .terms-contents .jss91 > span {
    font-size: 4.5vmin !important;
  }
  .terms-contents .MuiTypography-body1 {
    font-size: 3.5vmin;
  }
}
